/** Dependencies */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

/** Components */
import TopServiceItem from './TopServiceItem/TopServiceItem'

/** Styles */
import styles from './top-services.module.scss'

/** Utils */
import {request} from '../../../utils/request'
import * as types from '../../../utils/methodTypes'

const TopServices = ({ setModalSettings, header, subHeader, paragraph }) => {
	const lang = Cookies.get('lang')
	
	const [topServices, setTopServices] = useState([])
	
	const gridColumnCount = () => {
		let result = '';
		
		topServices.map(ts => result += ` ${100 / topServices.length}%`)
		
		return result
	}
	
	const handleItemClick = (item) => {
		setModalSettings({
			visible: true,
			title: item.title[lang],
			imageUrl: item.imageUrl,
			description: item.description[lang],
		})
	}
	
	const getTopServices = () => {
		request('/top-services', types.GET).then((response) => {
			setTopServices(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex));
		});
	}
	
	useEffect(() => {
		getTopServices();
	}, []);
	
	return (
		<section id={'top_services'} className={styles.topServices}>
			<div className={styles.title}>{header ? header[lang] : ''}</div>
			<div className={styles.description}>
				<div className={styles.slogan}>{subHeader ? subHeader[lang] : ''}</div>
				<div dangerouslySetInnerHTML={{__html: paragraph ? paragraph[lang] : ''}} className={styles.explanation} />
			</div>
			<div style={{gridTemplateColumns: gridColumnCount()}} className={styles.services}>
				{topServices.map(topService =>
					<TopServiceItem onClick={() => handleItemClick(topService)} imageAlt={topService.key} {...topService} />
				)}
			</div>
		</section>
	)
}

TopServices.propTypes = {
	setModalSettings: PropTypes.func,
	header: PropTypes.string,
	subHeader: PropTypes.string,
	paragraph: PropTypes.string
}

export default TopServices