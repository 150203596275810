/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'

/** Styles */
import styles from './cloud.module.scss'

const Cloud = ({ scale }) => {
	return (
		<div style={{ transform: `scale(${scale})` }} className={styles.cloud}>
			<div className={styles.bigCircle} />
			<div className={styles.smallCircle} />
		</div>
	)
}

Cloud.propTypes = {
	scale: PropTypes.number
}

export default Cloud