/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

/** Styles */
import styles from '../news.module.scss'

const NewsItem = ({ imageAlt, imageUrl, text, title, onItemClick }) => {
	const lang = Cookies.get('lang')
	
	return (
		<div onClick={onItemClick} className={styles.newsItem}>
			<img src={imageUrl} alt={imageAlt}/>
			<div className={styles.content}>
				<div className={styles.title}>{title[lang]}</div>
				<div className={styles.description}>{text[lang]}</div>
			</div>
		</div>
	)
}

NewsItem.propTypes = {
	imageAlt: PropTypes.string,
	imageUrl: PropTypes.string,
	text: PropTypes.string,
	title: PropTypes.string
}

export default NewsItem