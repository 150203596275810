/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Components */
import CloseButton from "../../common/CloseButton/CloseButton"
import Logo from "../../common/Logo/Logo"
import SocialMediaList from "../../SocialMediaList/SocialMediaList";
import Contact from "../../Contact/Contact";

/** Styles */
import styles from './side-bar.module.scss'

const SideBar = ({visible, setSideBarVisibility}) => {
  return (
    <div className={cx(styles.sideBar, {[styles.visible]: visible})}>
      <div className={styles.sideBarHeader}>
        <Logo className={styles.logo}/>
        <div onClick={() => setSideBarVisibility(false)} className={styles.closeIconContainer}>
          <CloseButton/>
        </div>
      </div>
      <SocialMediaList />
      <Contact />
    </div>
  )
}

SideBar.propTypes = {
  visible: PropTypes.bool,
  setSideBarVisibility: PropTypes.func,
}

export default SideBar