/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import cx from "classnames";

/** Icons */
import reversedLogo from '../../../assets/png/reversed-logo.png'
import logo from '../../../assets/png/logo.png'

/** Styles */
import styles from "./logo.module.scss";

const Logo = ({ className, reverse }) => {
  return (
    <img
      onClick={() => window.location.pathname = '/'}
      className={cx(styles.logo, className)}
      src={reverse ? reversedLogo : logo}
      alt={'firm logo'}
    />
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  reverse: PropTypes.bool
}

export default Logo