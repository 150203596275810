/** Dependencies */
import React, {createRef, useEffect, useState} from 'react'
import Cookies from 'js-cookie'
import emailjs from '@emailjs/browser';

/** Components */
import Input from '../../common/Input/Input'
import Contact from '../../Contact/Contact'
import Select from '../../common/Select/Select'

/** Icons */
import envelope from '../../../assets/svg/envelope.svg'

/** Utils */
import * as types from '../../../utils/methodTypes'
import {request} from '../../../utils/request'
import {usePrevious, useReactHash} from '../../../utils/hooks'
import Constants from "../../../utils/constants";

/** Styles */
import styles from './contact-us.module.scss'
import PropTypes from 'prop-types'
import { ThreeCircles } from  'react-loader-spinner'

const ContactUs = ({ header, subHeader, paragraph }) => {
	const lang = Cookies.get('lang') || 'en'

	const [contactReasons, setContactReasons] = useState([])
	const [selectedContactReason, setSelectedContactReason] = useState(contactReasons[0] || {})
	const [messageStatus, setMessageStatus] = useState('')
	const [loading, setLoading] = useState(false)
	
	const hash = useReactHash();

	const ref = createRef()

	const prevHash = usePrevious({ hash })

	const handleSelectChange = e => {
		let selectedReason = contactReasons.find(reason => reason.key === e.target.value);
		setSelectedContactReason(selectedReason)
	}
	
	const handleFormSubmit = (e) => {
		e.preventDefault()
		e.stopPropagation()

		setLoading(true)
		const payload = {
			subject: selectedContactReason.key,
			name: e.target.from_name.value,
			lastName: e.target.from_last_name.value,
			phone: e.target.phone.value,
			fromEmail: e.target.from_email.value,
			message: e.target.message.value,
		}

		request('/email', types.POST, payload).then((res) => {
			e.target.reset()
			setMessageStatus('success')
		}).catch(e => {
			console.error(e)
			setMessageStatus('failed')
		}).finally(() => setLoading(false))

		setTimeout(() => setMessageStatus(''), 5000)
	}
	
	const getContactReasons = () => {
		request('/contact-reasons', types.GET).then((response) => {
			setContactReasons(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex));
			setSelectedContactReason(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex)[0])
		});
	}
	
	useEffect(() => {
		if (prevHash === '#careers' && hash === '#careers')
			setSelectedContactReason(contactReasons.find(item => item.key === 'job_application'))
	}, [hash]);
	
	useEffect(() => {
		getContactReasons();
	}, []);
	
	return (
		<div className={styles.contactUs}>
			<div className={styles.title}>{header ? header[lang] : ''}</div>
			<div className={styles.question}>{subHeader ? subHeader[lang] : ''}</div>
			<div className={styles.slogan}>{paragraph ? paragraph[lang] : ''}</div>
			<form ref={ref} onSubmit={handleFormSubmit}>
				<div className={styles.row}>
					<Select onChange={handleSelectChange} value={selectedContactReason.key} name={'to_email'} data={contactReasons} />
					<Select hidden value={selectedContactReason.key} name={'to_name'} data={contactReasons} />
					<Input
						hidden
						name={'subject'}
					/>
				</div>
				<div className={styles.row}>
					<Input
						name={'from_name'}
						placeholder={Constants.contact_us.name_placeholder[lang]}
						required
					/>
					<Input
						name={'from_last_name'}
						placeholder={Constants.contact_us.lastname_placeholder[lang]}
						required
					/>
				</div>
				<div className={styles.row}>
					<Input
						name={'from_email'}
						type={'email'}
						placeholder={Constants.contact_us.email_placeholder[lang]}
						required
					/>
					<Input
						className={styles.inputNumber}
						name={'phone'}
						type={'number'}
						placeholder={Constants.contact_us.phone_placeholder[lang]}
						required
					/>
				</div>
				<div className={styles.row}>
					<Input
						name={'message'}
						placeholder={Constants.contact_us.message_placeholder[lang]}
						required
					/>
				</div>
				<div className={styles.formFooter}>
					<Contact home />
					<div className={styles.buttonContainer}>
						<button type={'submit'} disabled={loading}>
							<img src={envelope} alt="plane shaped envelope"/>
							<span>
								{Constants.contact_us.submit_button[lang]}
							</span>
						</button>
							<ThreeCircles
  							  	height = "30"
  							  	width = "30"
  							  	radius = "9"
  							  	color = 'white'
								visible={loading}
								ariaLabel="rings-loading"
  							/>
					</div>
					<div className={styles.messageStatus}>
						<span>
						{
							messageStatus === 'success' ? Constants.contact_us.success_message[lang] :
							messageStatus === 'failed' ? Constants.contact_us.failed_message[lang] : ''
						}
						</span>
					</div>
				</div>
			</form>
		</div>
	)
}

ContactUs.propTypes = {
	header: PropTypes.string,
	subHeader: PropTypes.string,
	paragraph: PropTypes.string
}

export default ContactUs