/** Dependencies */
import React, {useEffect, useState} from 'react'
import Cookies from "js-cookie";

/** Utils */
import {request} from '../../utils/request'
import * as types from '../../utils/methodTypes'
import Constants from "../../utils/constants";

/** Components */
import NewsItem from './NewsItem/NewsItem'
import Logo from '../common/Logo/Logo'

/** Styles */
import styles from './news.module.scss'
import PropTypes from "prop-types";

const News = ({ setModalSettings, header, subHeader, paragraph }) => {
	const lang = Cookies.get('lang')

	const [news, setNews] = useState([])
	
	const getNews = () => {
		request('/news', types.GET).then((response) => {
			setNews(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex));
		});
	}

	const handleItemClick = (item) => {
		setModalSettings({
			visible: true,
			title: item.title[lang],
			imageUrl: item.imageUrl,
			subHeader: item.text[lang],
			description: item.content[lang],
		})
	}
	
	useEffect(() => {
		getNews();
	}, []);
	
	return (
		<section id={'news'} className={styles.newsContainer}>
			<div className={styles.heading}>
				<Logo reverse className={styles.logo}/>
				<div className={styles.header}>{header ? header[lang] : ''}</div>
				<div className={styles.slogan}>{subHeader ? subHeader[lang] : ''}</div>
			</div>
			<div className={styles.news}>
				{news.map(newsItem => (
					<NewsItem
						onItemClick={() => handleItemClick(newsItem)}
						imageAlt={newsItem.key}
						{...newsItem}
					/>
				))}
			</div>
		</section>
	)
}

News.propTypes = {
	setModalSettings: PropTypes.func,
	header: PropTypes.string,
	subHeader: PropTypes.string,
	paragraph: PropTypes.string
}

export default News