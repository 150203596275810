/** Dependencies */
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/** Components */
import Leaf from './Leaf/Leaf'

/** Styles */
import styles from './tree.module.scss'

const Tree = ({ data, setModalSettings }) => {
	const [sectionVisible, setSectionVisibility] = useState(false)

	const chaseIntersection = (entries, observer) => {
		entries.forEach((entry) => {
			if(entry.isIntersecting && entry.intersectionRatio >= 0) {
				setSectionVisibility(true)
			} else setSectionVisibility(false)
		});
	}
	
	const options = {
		threshold: 0.1
	}
	
	const observer = new IntersectionObserver(chaseIntersection, options);
	
	const section = document.querySelector('#sustainability');
	
	section && observer.observe(section);
	
	return (
		<div className={styles.treeContainer}>
			<div className={classNames(styles.tree, styles[`_${data.length}`], { [styles.grown]: sectionVisible })}>
				<div className={styles.head}>
					<div className={classNames(styles.bubble, styles.second)} />
					<div className={styles.bubble} />
					<div className={styles.bigBubble} />
				</div>
				<div className={classNames(styles.body, styles[`_${data.length}`])}>
					{data.map((datum, i) => (
						<Leaf
							setModalSettings={setModalSettings}
							sectionVisible={sectionVisible}
							reverse={i%2 === 0}
							{...datum}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

Tree.propTypes = {
	data: PropTypes.array,
	setModalSettings: PropTypes.func
}

export default Tree