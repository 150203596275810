/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'

/** Styles */
import styles from './modal.module.scss'

const Modal = ({ title, subHeader, description, imageUrl, leaf, setVisibility }) => {
	const handleShadowClick = (e) => {
		e.preventDefault()
		setVisibility({ visible: false })
	}
	
	const handleModalClick = (e) => {
		e.stopPropagation()
	}
	
	return (
		<div onClick={handleShadowClick} className={styles.modalContainer}>
			{leaf ? (
				<div className={styles.layoutImageContainer}>
					<img className={styles.layoutImage} src={imageUrl} alt={'image'} />
					<div className={styles.title}>{title}</div>
					<div className={styles.description}>{description}</div>
				</div>
			) : (
				<div onClick={handleModalClick} className={styles.modal}>
					<div className={styles.image}><img src={imageUrl} alt={'image'} /></div>
					<div className={styles.title}>{title}</div>
					<div className={styles.subHeader}>{subHeader}</div>
					<div className={styles.description}>{description}</div>
				</div>
			)}
		</div>
	)
}

Modal.propTypes = {
	title: PropTypes.string,
	leaf: PropTypes.bool,
	subHeader: PropTypes.string,
	description: PropTypes.string,
	imageUrl: PropTypes.string,
	setVisibility: PropTypes.func,
}

export default Modal