/** Dependencies */
import React from 'react'

/** Styles */
import styles from './input.module.scss'

const Input = props => {
	return (
		<div hidden={props.hidden} className={styles.inputContainer}>
			<input {...props} />
			<div className={styles.line} />
		</div>
	)
}

export default Input