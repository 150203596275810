/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Icons */
import closeIcon from '../../../assets/svg/close.svg'
import whiteCloseIcon from '../../../assets/svg/close-white.svg'

/** Styles */
import styles from './close-button.module.scss'

const CloseButton = ({white}) => {
	return (
		<img
      className={cx(styles.closeButton, {[styles.white]: white})}
      src={white ? whiteCloseIcon : closeIcon}
      alt={'close'}
    />
	)
}

CloseButton.propTypes = {
	white: PropTypes.bool
}

export default CloseButton