/** Dependencies */
import React, {useState} from 'react'
import PropTypes from 'prop-types'

const SocialMediaItem = ({item, vertical = false}) => {
	const [hasGreyIcon, setHasGreyIcon] = useState(vertical)
	
	const handleMouseOver = () => {
		if (!vertical)
			setHasGreyIcon(true)
	}
	
	const handleMouseLeave = () => {
		if (!vertical)
			setHasGreyIcon(false)
	}
	
	return <li key={item.key}>
		<a onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} href={item.url}>
			<img src={vertical ? item.whiteImageUrl : (hasGreyIcon ? item.greyImageUrl : item.imageUrl)} alt={item.key}/>
			<p>{item.title}</p>
		</a>
	</li>
}

SocialMediaItem.propTypes = {
	item: PropTypes.object,
	vertical: PropTypes.bool
}

export default SocialMediaItem