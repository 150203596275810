/** Dependencies */
import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import Cookies from 'js-cookie'

/** Utils */
import {request} from '../../utils/request'
import * as types from '../../utils/methodTypes'

/** Components */
import Tree from '../common/Tree/Tree'
import Cloud from '../common/Cloud/Cloud'

/** Styles */
import './sustainability.scss'

const Sustainability = ({ setModalSettings, header, subHeader, paragraph }) => {
	const lang = Cookies.get('lang')

	const [sustainabilityArray, setSustainabilityArray] = useState([])
	
	const getSustainability = () => {
		request('/sustainability', types.GET).then((response) => {
			setSustainabilityArray(response.filter(item => !item.hidden));
		});
	}
	
	useEffect(() => {
		getSustainability();
	}, []);
	
	return (
		<section id={'sustainability'} className={'sustainability'}>
			<p className={'slogan'}>{header ? header[lang] : ''}</p>
			<div className={'cloudOne'}>
				<Cloud scale={1} />
			</div>
			<div className={'cloudTwo'}>
				<Cloud scale={0.75} />
			</div>
			<div className={'cloudThree'}>
				<Cloud scale={0.75} />
			</div>
			<div className={'cloudFive'}>
				<Cloud scale={0.75} />
			</div>
			<div className={'cloudSix'}>
				<Cloud scale={0.75} />
			</div>
			<div className={'cloudFour'}>
				<Cloud scale={0.5} />
			</div>
			<Tree data={sustainabilityArray} setModalSettings={setModalSettings} />
		</section>
	)
}

Sustainability.propTypes = {
	setModalSettings: PropTypes.func,
	header: PropTypes.string,
	subHeader: PropTypes.string,
	paragraph: PropTypes.string
}

export default Sustainability