/** Dependencies */
import React from 'react'
import classNames from 'classnames'

/** Styles */
import styles from './contact.module.scss'

const Contact = ({ home }) => {
  return (
    <div className={classNames(styles.contact, {[styles.white]: home})}>
      <div className={styles.phone}>+1 (201) 426-0865</div>
      <div className={styles.email}>ocean@portx.net</div>
      <div className={styles.address}>Portx 256 Paterson Plank road CARLSTADT, NJ 07072</div>
    </div>
  )
}

export default Contact