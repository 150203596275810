/** Dependencies */
import React from 'react'
import Cookies from "js-cookie";

/** Images */
import APM from '../../assets/jpg/apm.jpeg'
import Global from '../../assets/jpg/global.jpeg'
import NYCT from '../../assets/jpg/NYCT.jpeg'
import PNCT from '../../assets/jpg/PNCT.jpeg'

/** Styles */
import styles from './live-cams.module.scss'

/** Utils */
import Constants from "../../utils/constants";

const LiveCams = () => {
  const lang = Cookies.get('lang')

  return (
    <section id={'online_cams'} className={styles.liveCams}>
      <div className={styles.title}>{Constants.live_cams.title[lang]}</div>
      <div className={styles.terminals}>
        <a target={"_blank"} href={"https://www.apmterminals.com/en/port-elizabeth/practical-information/driver-information"} className={styles.terminal}>
          <img src={APM} alt="APM"/>
          <div className={styles.text}>APM Terminal</div>
        </a>
        <a target={"_blank"} href={"http://web.global-terminal.com/tview/"} className={styles.terminal}>
          <img src={Global} alt="Global"/>
          <div className={styles.text}>Global Terminal</div>
        </a>
        <a target={"_blank"} href={"https://globalterminalsnewyork.com/"} className={styles.terminal}>
          <img src={NYCT} alt="NYCT"/>
          <div className={styles.text}>NYCT Terminal</div>
        </a>
        <a target={"_blank"} href={"https://www.pnct.net/content/show/gatecameras"} className={styles.terminal}>
          <img src={PNCT} alt="PNCT"/>
          <div className={styles.text}>PNCT Terminal</div>
        </a>
      </div>
    </section>
  )
}

export default LiveCams