/** Dependencies */
import React from 'react'
import Cookies from "js-cookie";
import PropTypes from 'prop-types'

/** Icons */
import searchIcon from '../../../assets/svg/search-white.svg'

/** Styles */
import styles from './search-input.module.scss'

const SearchInput = ({}) => {
	const lang = Cookies.get('lang')

	return (
		<div className={styles.searchInput}>
			<input type="text" placeholder={lang === 'tr' ? "Ara" : "Search"}/>
			<img src={searchIcon} alt={'search'}/>
		</div>
	)
}

SearchInput.propTypes = {
	
}

export default SearchInput