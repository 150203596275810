/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

/** Icons */
import arrowRight from '../../../assets/svg/arrow-right-tail-white.svg'

/** Styles */
import styles from '../services.module.scss'

const ServiceItem = ({ imageUrl, imageAlt, title, onClick }) => {
	const lang = Cookies.get('lang')
	
	return (
		<div onClick={onClick} className={styles.imageContainer}>
			<img src={imageUrl} alt={imageAlt} />
			<div className={styles.text}>{title[lang]}</div>
			<img className={styles.arrow} src={arrowRight} alt="arrow"/>
		</div>
	)
}

ServiceItem.propTypes = {
	imageUrl: PropTypes.string,
	imageAlt: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func,
}

export default ServiceItem