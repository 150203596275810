/** Dependencies */
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";

/** Hooks */
import {useMediaQuery} from '../../utils/hooks'

/** Components */
import CarouselItem from './CarouselItem/CarouselItem'

/** Icons */
import rightArrow from '../../assets/svg/arrow-right.svg'
import leftArrow from '../../assets/svg/arrow-left.svg'

/** Utils */
import { request } from '../../utils/request'
import * as types from '../../utils/methodTypes';

/** Styles */
import './carousel.scss'

const NextArrow = (props) => {
	const { className, style, onClick } = props;
	return (
		<img
			src={rightArrow}
			className={className}
			style={{ ...style }}
			onClick={onClick}
			alt={'right arrow'}
		/>
	);
}

const PrevArrow = (props) => {
	const { className, style, onClick } = props;
	return (
		<img
			src={leftArrow}
			alt={'left arrow'}
			className={className}
			style={{ ...style }}
			onClick={onClick}
		/>
	);
}

const Carousel = () => {
	const isMobile = useMediaQuery('(max-width: 768px)')
	
	const [carouselItems, setCarouselItems] = useState([])
	
	const settings = {
		dots: true,
		fade: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 54000,
		nextArrow: isMobile ? <></> : <NextArrow />,
		prevArrow: isMobile ? <></> : <PrevArrow />,
	};
	
	const getBanners = () => {
		request('/banners', types.GET).then((bannersResponse) => {
			request('/news', types.GET).then((newsResponse) => {
				setCarouselItems([
					...bannersResponse.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex),
					...newsResponse.filter(item => !item.hidden).filter(newsItem => newsItem.showAsBanner).sort((a, b) => a.orderIndex - b.orderIndex)
				])
			});
		});
	}
	
	const getNews = () => {
	
	}
	
	const setInitialData = () => {
		getBanners();
		getNews();
	}
	
	useEffect(() => {
		setInitialData();
	}, []);
	
	return (
		<section id={'home'} className={'sliderContainer'}>
			<Slider {...settings}>
				{carouselItems.map(banner => <CarouselItem imageAlt={banner.key} {...banner} />)}
			</Slider>
		</section>
	)
}

export default Carousel
