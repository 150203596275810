/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Cookies from 'js-cookie'

/** Utils */
import {useMediaQuery} from '../../../../utils/hooks'

/** Styles */
import styles from './leaf.module.scss'

const Leaf = ({ reverse, imageUrl, title, description, sectionVisible, setModalSettings }) => {
	const lang = Cookies.get('lang')
	
	const isMobile = useMediaQuery('(max-width: 768px)')

	const handleContentClick = () => {
		setModalSettings({
			visible: true,
			leaf: true,
			imageUrl: imageUrl,
			title: title[lang],
			description: description[lang],
		})
	}
	
	return (
		<div className={classNames(styles.leafContainer, {[styles.reverse]: reverse, [styles.grown]: sectionVisible})}>
			<div onClick={handleContentClick} className={styles.content}>
				<div className={styles.image}>
					<img src={imageUrl} alt="icon"/>
				</div>
				<div className={styles.title}>{title[lang]}</div>
				{!isMobile && <div className={styles.description}>{description[lang]}</div>}
			</div>
			<div className={styles.leaf}>
				<div className={styles.branch} />
			</div>
		</div>
	)
}

Leaf.propTypes = {
	sectionVisible: PropTypes.bool,
	reverse: PropTypes.bool,
	imageUrl: PropTypes.string,
	title: PropTypes.string,
	setModalSettings: PropTypes.func,
	description: PropTypes.string
}

export default Leaf