/** Dependencies */
import React, {useState} from 'react'
import YouTube from 'react-youtube';
import Cookies from "js-cookie";

/** Components */
import CloseButton from '../common/CloseButton/CloseButton'

/** Hooks */
import {useMediaQuery} from '../../utils/hooks'

/** Styles */
import styles from './player.module.scss'

const Player = () => {
	const lang = Cookies.get('lang')

	const [playerVisibility, setPlayerVisibility] = useState(false)
	
	const isMobile = useMediaQuery('(max-width: 768px)')
	
	const playerOpts = {
		width: !isMobile ? '1000' : '320',
		height: !isMobile ? '500' : '160',
		playerVars: {autoplay: 1}
	}
	
	const handleClickOutside = (e) => {
		e.preventDefault()
		e.stopPropagation()
		
		setPlayerVisibility(false)
	}
	
	return (
		<section className={styles.player}>
			<div className={styles.shadow} />
			{/*<div onClick={() => setPlayerVisibility(true)} className={styles.playButton}><span>/!*Constants.player.play[lang]*!/</span></div>*/}
			{playerVisibility && <div onClick={handleClickOutside} className={styles.playerModalContainer}>
				<div className={styles.playerModal}>
					<YouTube videoId="iNujGyQlp-I" opts={playerOpts}  />
					<div onClick={() => setPlayerVisibility(false)} className={styles.closeButtonContainer}>
						<CloseButton />
					</div>
				</div>
			</div>}
		</section>
	)
}

export default Player