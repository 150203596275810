/** Dependencies */
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import cx from 'classnames'
import ReactGA from 'react-ga4'

/** Utils */
import {request} from '../../../utils/request'
import * as types from '../../../utils/methodTypes'

/** Styles */
import styles from './nav-bar.module.scss'

const NavBar = ({reverse}) => {
	const lang = Cookies.get('lang')
	
	const [selectedItem, setSelectedItem] = useState('home')
	const [navItems, setNavItems] = useState([])
	
	const changeNav = (entries, observer) => {
		entries.forEach((entry) => {
			if(entry.isIntersecting && entry.intersectionRatio >= 1) {
				const id = entry.target.getAttribute('id');
				setSelectedItem(id)
			}
		});
	}

	const options = {
		threshold: 1
	}
	
	const observer = new IntersectionObserver(changeNav, options);

	const sections = document.querySelectorAll('section');
	sections.forEach((section) => {
		observer.observe(section);
	});
	
	const getNavItems = () => {
		request('/nav-items', types.GET).then((response) => {
			setNavItems(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex));
		});
	}

	const eventTrack = (url) => {
		ReactGA.event({
			category: 'Navbar Page Click',
			action: url.substring(1),
			label: url.substring(1)
		});
	}
	
	useEffect(() => {
		getNavItems();
	}, []);
	
	return (
		<ul className={cx(styles.navBar, { [styles.reverse]: reverse })}>
			{navItems.map(item => (
				<li className={styles.navBarItem} onClick={eventTrack.bind(this,item.url)} key={item.key}>
					<a href={item.url}>{item.text[lang]}</a>
					<div className={cx(styles.stroke, {[styles.active]: selectedItem?.includes(item.key)})}/>
					{item?.subItems?.length > 0 && (
						<ul className={styles.subItems}>
							{item.subItems.map(subItem => (
								<li key={subItem.key} onClick={eventTrack.bind(this,subItem.url)}>
									<a href={subItem.url}>{subItem.text}</a>
									<div className={styles.stroke}/>
								</li>
							))}
						</ul>
					)}
				</li>
			))}
		</ul>
	)
}

NavBar.propTypes = {
	reverse: PropTypes.bool,
}

export default NavBar