/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'

/** Components */
import MapContainer from './MapContainer'
import ContactUs from './ContactUs/ContactUs'

/** Styles */
import styles from './map.module.scss'

const Map = ({ header, subHeader, paragraph }) => {
	return (
		<section id={'careers'} className={styles.mapContainer}>
			<MapContainer />
			<ContactUs header={header} subHeader={subHeader} paragraph={paragraph} />
		</section>
	)
}

Map.propTypes = {
	header: PropTypes.string,
	subHeader: PropTypes.string,
	paragraph: PropTypes.string
}

export default Map