/** Dependencies */
import React from 'react'
import Cookies from "js-cookie";

/** Components */
import Logo from '../common/Logo/Logo'
import SocialMediaList from '../SocialMediaList/SocialMediaList'

/** Styles */
import styles from './footer.module.scss'

/** Utils */
import Constants from '../../utils/constants'

const Footer = () => {
  const lang = Cookies.get('lang')

  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        <span>PortX inc.</span> © 2022 {Constants.footer.copyright[lang]}
      </div>
      <Logo reverse className={styles.logo} />
      <SocialMediaList vertical />
    </footer>
  )
}

export default Footer