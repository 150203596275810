/** Dependencies */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

/** Components */
import TopServices from './TopServices/TopServices'
import ServiceItem from './ServiceItem/ServiceItem'

/** Utils */
import {request} from '../../utils/request'
import * as types from '../../utils/methodTypes'

/** Styles */
import styles from './services.module.scss'

const Services = ({ setModalSettings, header, subHeader, paragraph }) => {
	const lang = Cookies.get('lang')
	
	const [services, setServices] = useState([])
	
	const handleItemClick = (item) => {
		setModalSettings({
			visible: true,
			title: item.title[lang],
			imageUrl: item.imageUrl,
			description: item.description[lang],
		})
	}
	
	const getServices = () => {
		request('/services', types.GET).then((response) => {
			setServices(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex));
		});
	}
	
	useEffect(() => {
		getServices();
	}, []);
	
	return (
		<>
			<section id={'services'} className={styles.services}>
				{services.map(service => <ServiceItem onClick={() => handleItemClick(service)} imageAlt={service.key} {...service} />)}
			</section>
			<TopServices
				header={header}
				subHeader={subHeader}
				paragraph={paragraph}
				setModalSettings={setModalSettings}
			/>
		</>
	)
}

Services.propTypes = {
	setModalSettings: PropTypes.func,
	header: PropTypes.string,
	subHeader: PropTypes.string,
	paragraph: PropTypes.string
}

export default Services