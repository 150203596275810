/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import ReactPlayer from 'react-player'

/** Utils */
import {useMediaQuery} from '../../../utils/hooks'
import Constants from '../../../utils/constants';

/** Styles */
import styles from './carousel-item.module.scss'

const CarouselItem = ({ imageAlt, title, text, imageUrl, mobileImageUrl, videoUrl, isVideo }) => {
	const lang = Cookies.get('lang')
	
	const isMobile = useMediaQuery('(max-width: 768px)')

	return (
		<div className={styles.imageContainer}>
			{!isVideo ? (
				<>
					<div className={styles.sector}>{title[lang]}</div>
					<div className={styles.slogan}>{text[lang]}</div>
					<img src={isMobile ? mobileImageUrl : imageUrl} alt={imageAlt}/>
					<a href={'#top_services'} className={styles.whoWeAre}>{Constants.carousel_item.who_we_are[lang]}</a>
				</>
			) : (
				<ReactPlayer
					playing={true}
					loop={true}
					style={{minHeight: '100vh', minWidth: '100vw'}}
					url={videoUrl}
					controls={false}
					muted
				/>
			)}
		</div>
	)
}

CarouselItem.propTypes = {
	imageAlt: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	imageUrl: PropTypes.string,
	videoUrl: PropTypes.string,
	isVideo: PropTypes.bool,
	mobileImageUrl: PropTypes.string
}

export default CarouselItem