/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

/** Styles */
import styles from './specialty.module.scss'

const Specialty = ({ imageAlt, title, description, imageUrl, iconExp }) => {
	const lang = Cookies.get('lang')
	
	return (
		<div className={styles.specialty}>
			<p className={styles.title}>{title[lang]}</p>
			<p className={styles.description}>{description[lang]}</p>
			<img src={imageUrl} alt={imageAlt} />
		</div>
	)
}

Specialty.propTypes = {
	imageAlt: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	imageUrl: PropTypes.string,
	iconExp: PropTypes.string,
}

export default Specialty