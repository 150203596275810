/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

/** icons */
import arrowRight from '../../../../assets/svg/arrow-right-tail.svg'

/** components */
import ServiceItem from '../../ServiceItem/ServiceItem'

/** styles */
import styles from '../top-services.module.scss'

const TopServiceItem = ({ imageUrl, imageAlt, title, onClick }) => {
	const lang = Cookies.get('lang')
	
	return (
		<div onClick={onClick} className={styles.service}>
			<img className={styles.icons} src={imageUrl} alt={imageAlt}/>
			<p className={styles.text}>{title[lang]}</p>
			<div className={styles.arrow}>
				<p className={styles.readMore}>Read More</p>
				<img src={arrowRight} alt="arrow"/>
			</div>
		</div>
	)
}

ServiceItem.propTypes = {
	imageUrl: PropTypes.string,
	imageAlt: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func,
}

export default TopServiceItem