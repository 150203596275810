export default {
  top_services: {
    title: {
      en: 'TOP SERVICES',
      tr: 'EN İYİ HİZMETLER',
      es: 'SERVICIOS PRINCIPALES'
    },
    slogan: {
      en: 'Serving All Ports Nationwide',
      tr: 'Ülke Geneline Hizmet',
      es: 'Sirviendo a todos los puertos a nivel nacional'
    },
    explanation1: {
      en: 'PORTX provides timely, door to port, port to door, same day and next day export import container delivery services.',
      tr: 'PORTX, zamanında, kapıdan limana, limandan kapıya, aynı gün ve ertesi gün ihracat ithalat konteyner teslimi hizmetleri vermektedir.',
      es: 'PORTX brinda servicios de entrega de contenedores de exportación e importación oportunos, puerta a puerto, puerto a puerta, el mismo día y al día siguiente.'
    },
    explanation2: {
      en: 'We are a container drayage company servicing major terminals. Our company utilizes owner operators and dedicated company owned trucks to offer our clients capacity in the terminals.',
      tr: 'Biz büyük terminallere hizmet veren bir konteyner taşıma şirketiyiz. Şirketimiz, müşterilerimize terminallerde kapasite sunmak için sahibi operatörler ve özel şirkete ait kamyonlar kullanır.',
      es: 'Somos una empresa de acarreo de contenedores que da servicio a las principales terminales. Nuestra empresa utiliza operadores propietarios y camiones exclusivos propiedad de la empresa para ofrecer a nuestros clientes capacidad en las terminales.'
    }
  },
  footer: {
    copyright: {
      en: 'All rights reserved.',
      tr: 'her hakkı saklıdır.',
      es: 'Reservados todos los derechos.'
    }
  },
  carousel_item: {
    who_we_are: {
      en: 'WHO WE ARE',
      tr: 'BİZ KİMİZ',
      es: 'QUE ES PORTX'
    }
  },
  contact_us: {
    title: {
      en: 'CONTACT US',
      tr: 'İLETİŞİME GEÇ',
      es: 'CONTÁCTENOS'
    },
    question: {
      en: 'Have questions?',
      tr: 'Soruların mı var?',
      es: '¿Tiene preguntas?'
    },
    slogan: {
      en: 'Get in touch!',
      tr: 'İrtibat kur!',
      es: '¡Ponerse en contacto!'
    },
    name_placeholder: {
      en: 'Name',
      tr: 'Ad',
      es: 'Nombre'
    },
    lastname_placeholder: {
      en: 'Last Name',
      tr: 'Soyad',
      es: 'Apellido'
    },
    email_placeholder: {
      en: 'Email',
      tr: 'Email',
      es: 'Email'
    },
    phone_placeholder: {
      en: 'Phone',
      tr: 'Telefon',
      es: 'Teléfono'
    },
    message_placeholder: {
      en: 'Message',
      tr: 'Mesaj',
      es: 'Mensaje'
    },
    submit_button: {
      en: 'GET IN TOUCH',
      tr: 'İRTİBAT KUR',
      es: 'PONERSE EN CONTACTO'
    },
    success_message: {
      en: 'Message sent successfully.',
      tr: 'Mesajınız başarıyla gönderildi.',
      es: 'Mensaje enviado con éxito.'
    },
    failed_message: {
      en: 'Message could not be sent!',
      tr: 'Mesajınız gönderilemedi!',
      es: 'No se pudo enviar el mensaje!'
    },
  },
  news: {
    header: {
      en: 'News',
      tr: 'Haberler',
      es: 'Noticias'
    },
    slogan: {
      en: 'Serving all ports nationwide have an impact nationwide',
      tr: 'Ülke genelinde hizmet, ülke genelinde fark oluşturur',
      es: 'Atendiendo a todos los puertos a nivel nacional tener un impacto a nivel nacional'
    },
  },
  player: {
    play: {
      en: 'Play',
      tr: 'Oynat',
      es: 'Reproducir'
    },
  },
  live_cams: {
    title: {
      en: 'PORTS\' LIVE CAMS',
      tr: 'CANLI LİMAN KAMERALARI',
      es: 'CÁMARAS EN VIVO DE LOS PUERTOS'
    }
  }
}