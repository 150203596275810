/** Dependencies */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/** Images */
import Logo from '../../assets/png/logo512.png'

/** Styles */
import styles from './loading-screen.module.scss'

const LoadingScreen = props => {
	const [transitionStarted, setTransitionStarted] = useState(false)
	const [visible, setVisibility] = useState(true)
	const [seconds, setSeconds] = useState(5)
	
	
	useEffect(() => {
		let timeleft = 5;
		
		const timer = setInterval(function(){
			timeleft -= 1
			setSeconds(timeleft)
		}, 1000);
		
		setTimeout(() => {
			setTransitionStarted(true)
		}, 3000)
		
		setTimeout(() => {
			setVisibility(false)
			
			clearInterval(timer)
		}, 5000)
	}, [])
	
	return (
		<>
			{visible && (
				<div className={classNames(styles.loadingScreen, {[styles.transitioned]: transitionStarted})}>
					<div className={styles.logo}>
						<div className={styles.letter}>P</div>
						<div className={styles.rudder}><img src={Logo} alt="logo"/></div>
						<div className={styles.letter}>RTX</div>
					</div>
				</div>
			)}
		</>
	)
}

LoadingScreen.propTypes = {

}

export default LoadingScreen