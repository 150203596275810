/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

/** Styles */
import styles from './select.module.scss'

const Select = ({ value, name, data, hidden=false, onChange }) => {
	const lang = Cookies.get('lang')
	
	return (
		<div hidden={hidden} className={styles.selectContainer}>
			<select value={value} name={name} onChange={onChange}>
				{data.map((item, i) => <option key={i} value={item.key}>{item.value[lang]}</option>)}
			</select>
			<div className={styles.line} />
		</div>
	)
}

Select.propTypes = {
	data: PropTypes.array,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	hidden: PropTypes.bool,
}

export default Select