/** Dependencies */
import React, {useCallback, useEffect, useState} from 'react'
import cx from 'classnames'

/** Icons */
import upArrow from '../../../assets/svg/up-arrow.svg'

/** Styles */
import styles from './scroll-to-top.module.scss'
import {useMediaQuery} from "../../../utils/hooks";

const ScrollToTop = () => {
	const [visible, setVisibility] = useState(false)

	const isMobile = useMediaQuery('(max-width: 768px)')

	const handleScroll = useCallback(() => {
		if (window.scrollY >= 100)
			setVisibility(true)
		if (window.scrollY < 100)
			setVisibility(false)
	}, [window.scrollY])
	
	const handleClick = () => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}
	
	useEffect(() => {
		window.addEventListener("scroll", handleScroll)
		
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])
	
	return !isMobile ? (
		<div onClick={handleClick} className={cx(styles.scrollButton, {[styles.visible]: visible})}>
			<img src={upArrow} alt="up arrow"/>
		</div>
	) : (<></>)
}

export default ScrollToTop