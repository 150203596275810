/** Dependencies */
import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

/** Components */
import App from "../../App";
import LiveCams from "../LiveCams/LiveCams";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const Navigation = () => {
  return (
    <div style={{ position: "relative" }}>
      <LoadingScreen />
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/online-cams" element={<LiveCams />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default Navigation