/** Dependencies */
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Components */
import CloseButton from '../../common/CloseButton/CloseButton'
import Logo from '../../common/Logo/Logo'
import SearchInput from '../../common/SearchInput/SearchInput'

/** Styles */
import styles from './top-bar.module.scss'

const TopBar = ({visible, setTopBarVisibility}) => {
	return (
		<div className={cx(styles.topBar, {[styles.visible]: visible})}>
			<div className={styles.topBarHeader}>
				<Logo className={styles.logo} reverse />
				<div onClick={() => setTopBarVisibility(false)} className={styles.closeIconContainer}>
					<CloseButton white />
				</div>
			</div>
			<SearchInput />
		</div>
	)
}

TopBar.propTypes = {
	visible: PropTypes.bool,
	setTopBarVisibility: PropTypes.func,
}

export default TopBar