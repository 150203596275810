/** Dependencies */
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/** Utils */
import {request} from '../../utils/request'
import * as types from '../../utils/methodTypes'

/** Components */
import SocialMediaItem from "./SocialMediaItem/SocialMediaItem";

/** Styles */
import styles from './social-media-list.module.scss'

const SocialMediaList = ({ vertical }) => {
  const [socialMediaData, setSocialMediaData] = useState([])
  
  const getSocialMediaData = () => {
    request('/social-media-accounts', types.GET).then((response) => {
      setSocialMediaData(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex));
    });
  }
  
  useEffect(() => {
    getSocialMediaData();
  }, []);

  return (
    <ul className={cx(styles.socialMediaList, {[styles.vertical]: vertical})}>
      {socialMediaData.map(datum => <SocialMediaItem key={datum.key} item={datum} vertical={vertical} />)}
    </ul>
  )
}

SocialMediaList.propTypes = {
  vertical: PropTypes.bool
}

export default SocialMediaList