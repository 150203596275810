/** Dependencies */
import React, {useEffect, useState} from 'react'

/** Components */
import Specialty from './Specialty/Specialty'

/** Utils */
import {request} from '../../utils/request'
import * as types from '../../utils/methodTypes'

/** Styles */
import styles from './specialties.module.scss'

const Specialties = props => {
	const [specialties, setSpecialties] = useState([])
	
	const getSpecialties = () => {
		request('/specialties', types.GET).then((response) => {
			setSpecialties(response.filter(item => !item.hidden).sort((a, b) => a.orderIndex - b.orderIndex));
		});
	}
	
	useEffect(() => {
		getSpecialties();
	}, []);
	
	return (
		<section className={styles.specialties}>
			{
				specialties.map(specialty => (
					<Specialty
						imageAlt={specialty.key}
						{...specialty}
					/>
				))
			}
		</section>
	)
}

export default Specialties